function work_1() {
	return {
		date: "Mai 2022 - Juillet 2022",
		title: "TERIA - Exagone",
		subtitle: "Développeur Stagiaire",
		logo: "./logo-teria.png",
		description: [
			"- Projet de station GNSS sur Raspberry Pi",
			"- Installation d'un serveur MQTT",
			"- Travaux quotidiens de maintenance du réseau"
		],
		link: "https://www.reseau-teria.com/",
		keywords: [
			"TERIA - Exagone",
			"Stage",
			"Informatique",
			"Développeur",
			"Jérémy TO",
		],
		/*body: (
			<React.Fragment>
				<h2>- Projet de station GNSS sur Raspberry Pi <br></br>
					- Installation d'un serveur MQTT</h2>
			</React.Fragment>
		)*/
	};
}

function work_2() {
	return {
		date: "Décembre 2022 - Septembre 2023",
		title: "AI Pro Management",
		subtitle: "Apprenti Développeur Full Stack",
		logo: "./logo-aiprm.png",
		description: "Développement d'une web application de gestion de projets pour les professionnels du secteur automobile utilisant l'intelligence artificielle pour la prédiction des paramètres relatifs à un projet",
		link: "https://www.aipromanagement.com/",
		keywords: [
			"AI Pro Management",
			"Développeur",
			"Full Stack",
			"Informatique",
			"Intelligence artificielle",
			"Applications webs",
			"Jérémy TO",
		],
		/*body: (
			<React.Fragment>
				<h2>Développement d'une web application de gestion de projets
					pour les professionnels du secteur automobile utilisant
					l'intelligence artificielle pour la prédiction des paramètres
					relatifs à un projet</h2>
			</React.Fragment>
		)*/
	};
}

function work_3() {
	return {
		date: "Septembre 2024 - Septembre 2027",
		title: "Crédit Agricole Personal Finance et Mobility",
		subtitle: "Apprenti Ingénieur d'études et développement",
		logo: "./logo-capfm.jpg",
		description: "",
		link: "https://www.ca-personalfinancemobility.com/",
		keywords: [
			"Crédit Agricole",
			"Personal Finance et Mobility",
			"Développeur",
			"Full Stack",
			"Informatique",
			"Jérémy TO",
		],
		/*body: (
			<React.Fragment>
			</React.Fragment>
		)*/
	};
}

const Works = [work_3, work_2, work_1];

export default Works;