import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/a-propos-de-moi/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/a-propos-de-moi.css";

const ProposDeMoi = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "a_propos_de_moi");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`À Propos De Moi`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="a-propos-de-moi" />
				<div className="content-wrapper">
					<div className="a-propos-de-moi-logo-container">
						<div className="a-propos-de-moi-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="a-propos-de-moi-container">
						<div className="a-propos-de-moi-main">
							<div className="a-propos-de-moi-right-side">
								<div className="title a-propos-de-moi-title">
									{INFO.a_propos_de_moi.title}
								</div>

								<div className="subtitle a-propos-de-moi-subtitle">
									{INFO.a_propos_de_moi.description}
								</div>
							</div>

							<div className="a-propos-de-moi-left-side">
								<div className="a-propos-de-moi-image-container">
									<div className="a-propos-de-moi-image-wrapper">
										<img
											src="photo.jpg"
											alt="a-propos-de-moi"
											className="a-propos-de-moi-image"
										/>
									</div>
								</div>

								<div className="a-propos-de-moi-socials">
									<Socials />
								</div>
							</div>
						</div>
						<div className="a-propos-de-moi-socials-mobile">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ProposDeMoi;
