import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./styles/works.css";

const Works = (props) => {
	const { title, subtitle, description, date, logo, link } = props;

	return (
		<React.Fragment>
			<div className="homepage-work">
				<div className="homepage-work-content">
					<div className="homepage-work-logo">
						<img src={logo} alt={title} className="work-image" />
					</div>
					<div className="homepage-work-details">
						<div className="homepage-work-date">
							|&nbsp;&nbsp;&nbsp;{date}
						</div>
						<div className="homepage-work-title">{title}</div>
						<div className="homepage-work-subtitle">{subtitle}</div>
						<div className="homepage-work-description">
							{Array.isArray(description) ? (
								description.map((line, index) => (
									<p key={index}>{line}</p>
								))
							) : (
								<p>{description}</p>
							)}
						</div>
						<div className="homepage-work-link">
							<a href={link} target="_blank" rel="noreferrer">
								Détails{" "}
								<FontAwesomeIcon
									style={{ fontSize: "10px" }}
									icon={faChevronRight}
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Works;