import React from "react";
import { Link } from "react-router-dom";

import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "accueil"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">Accueil</Link>
							</li>
							<li
								className={
									active === "a-propos-de-moi"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/a-propos-de-moi">À propos de moi</Link>
							</li>
							<li
								className={
									active === "projets"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projets">Projets</Link>
							</li>
							<li
								className={
									active === "etudes"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/etudes">Études</Link>
							</li>
							<li
								className={
									active === "works"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/experiences">Expériences</Link>
							</li>
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
